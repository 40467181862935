import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Edit from './components/Edit'; // Importa el componente Edit correctamente
import Box from '@mui/material/Box';
import { MyProvider } from './MyContext';
import IniciPage from './components/IniciPage';
import ProjectePage from './components/ProjectePage';
import MostreigPage from './components/MostreigPage';
import MaterialsPage from './components/MaterialsPage';
import IniciSessioPage from './components/IniciSessioPage';
import ContactePage from './components/ContactePage';
import InstruccionsPage from './components/InstruccionsPage';
import AplicatiuPage from './components/AplicatiuPage';
import Bloc0Page from './components/Bloc0page';
import Bloc1Page from './components/Bloc1page';
import Bloc2Page from './components/Bloc2page';
import Bloc3Page from './components/Bloc3page';
import Bloc4Page from './components/Bloc4page';
import Bloc5Page from './components/Bloc5page';
import Bloc6Page from './components/Bloc6page';
import Bloc7Page from './components/Bloc7page';
import './App.css'; // Importa el archivo CSS

function App() {
  return (
    <MyProvider>
      <Router>
        <div className="app-container">
          <Box className="main-content" sx={{ display: 'flex', flexDirection: 'column', width: '100%', height: '100%', overflow: 'hidden' }}>
            <Routes>
              {/* Carga directamente la página de inicio sin la selección de personaje */}
              <Route path="/" element={<IniciPage />} />
              <Route path="/inici" element={<IniciPage />} />
              <Route path="/aplicatiu" element={<AplicatiuPage />} />
              <Route path="/Edit/:collection/:id" element={<Edit />} /> {/* Corrige la ruta */}

              {/* Rutas para los bloques */}
              <Route path="/bloque/0" element={<Bloc0Page />} />
              <Route path="/bloque/1" element={<Bloc1Page />} />
              <Route path="/bloque/2" element={<Bloc2Page />} />
              <Route path="/bloque/3" element={<Bloc3Page />} />
              <Route path="/bloque/4" element={<Bloc4Page />} />
              <Route path="/bloque/5" element={<Bloc5Page />} />
              <Route path="/bloque/6" element={<Bloc6Page />} />
              <Route path="/bloque/7" element={<Bloc7Page />} />

              {/* Añade rutas para otros bloques o páginas en el futuro */}
            </Routes>
          </Box>
        </div>
      </Router>
    </MyProvider>
  );
}

export default App;
