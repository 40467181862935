import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, TextField, MenuItem, Select, InputLabel, FormControl, Checkbox, ListItemText } from '@mui/material';
import { useNavigate, useParams, Link } from 'react-router-dom';
import BannerImage from '../assets/banner-image.png';
import '../App.css';
import axios from 'axios';

const Bloc2Page = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [formData, setFormData] = useState({
    vegSiNo: '',
    plantSiNo: '',
    plantCategory: [],
    arbreSiNo: '',
    treeCategory: [],
    restorg: '',
    organicCategory: [],
  });
  const [photo, setPhoto] = useState(null); // Archivo de la foto subida
  const [photoUrl, setPhotoUrl] = useState(''); // URL de la foto existente
  const [message, setMessage] = useState('');

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const isEdit = queryParams.get('edit') === 'true';
    const documentId = queryParams.get('id');

    if (isEdit && documentId) {
      const fetchData = async () => {
        try {
          const response = await axios.get(`https://soil.quanta-labs.com/form/pregunta_2/${documentId}`);
          setFormData(response.data.document || response.data);
        } catch (error) {
          console.error('Error al cargar los datos:', error);
          setMessage('Error al cargar los datos');
        }
      };

      fetchData();
    }
  }, [id]);

  const handleNext = () => {
    if (currentSlide < 4) {
      setCurrentSlide((prev) => prev + 1);
    } else {
      handleSubmit();
    }
  };

  const handlePrevious = () => {
    if (currentSlide > 0) {
      setCurrentSlide((prev) => prev - 1);
    }
  };

  const handleChange = (field) => (e) => {
    setFormData({
      ...formData,
      [field]: e.target.value,
    });
  };

  const handlePhotoChange = (e) => {
    const selectedFile = e.target.files[0];
    setPhoto(selectedFile);
  };

  const handleSubmit = async () => {
    try {
      const queryParams = new URLSearchParams(window.location.search);
      const isEdit = queryParams.get('edit') === 'true';
      const documentId = queryParams.get('id');

      const jsonData = {
        document: {
          vegSiNo: formData.vegSiNo,
          plantSiNo: formData.plantSiNo,
          plantCategory: formData.plantCategory,
          arbreSiNo: formData.arbreSiNo,
          treeCategory: formData.treeCategory,
          restorg: formData.restorg,
          organicCategory: formData.organicCategory,
        }
      };

      let response;
      if (isEdit && documentId) {
        response = await axios.patch(
          `https://soil.quanta-labs.com/form/pregunta_2/${documentId}`,
          jsonData,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer test',
            }
          }
        );
      } else {
        const formDataToSend = new FormData();
        formDataToSend.append('document', JSON.stringify(jsonData.document));

        if (photo) {
          formDataToSend.append('file', photo, photo.name);
        }

        response = await axios.post(
          'https://soil.quanta-labs.com/form/pregunta_2/',
          formDataToSend,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: 'Bearer test',
            }
          }
        );
      }

      console.log('Respuesta de la API:', response.data);
      setMessage('Datos enviados correctamente');
      localStorage.setItem('updateFormList', Date.now());

      // Espera un poco antes de navegar para asegurar que los datos se han actualizado
      setTimeout(() => {
        navigate('/inici');
      }, 1000);
    } catch (error) {
      console.error('Error al enviar datos:', error);
      if (error.response) {
        console.error('Respuesta del servidor:', error.response.data);
        setMessage(`Error al enviar datos: ${JSON.stringify(error.response.data)}`);
      } else {
        console.error('Error:', error.message);
        setMessage('Error al enviar datos');
      }
    }
  };


  const renderSlideContent = () => {
    switch (currentSlide) {
      case 0:
        return (
          <>
            <Typography variant="h4" component="h2" align="center" gutterBottom>
              <br />
              <br />
              VEGETACIÓ!!
              <br />
              <br />
            </Typography>
            <Typography variant="body1" gutterBottom>
              Comencem el bloc 2, dedicat a la vegetació.
              <br />
              INSTRUCCIONS:
              <ul>
                <li>Situa’t dins de la teva parcel·la i observa-la.</li>
                <li>Mira si hi ha vegetació.</li>
                <li>A continuació, contesta la següent pregunta i adjunta les fotografies.</li>
              </ul>
              PREGUNTA 2.1: Hi ha vegetació?
              <br />
            </Typography>
            <FormControl fullWidth>
              <InputLabel>Resposta</InputLabel>
              <Select
                value={formData.vegSiNo}
                onChange={handleChange('vegSiNo')}
                label="Resposta"
              >
                <MenuItem value="Si">Sí</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </FormControl>
          </>
        );

      case 1:
        return (
          <>
            <Typography variant="h4" component="h2" align="center" gutterBottom>
              <br />
              <br />
              PLANTES I HERBES!!
              <br />
              <br />
            </Typography>
            <Typography variant="body1" gutterBottom>
              Després de veure si hi ha vegetació, ara ens centrarem en les plantes i herbes.
              <br />
              INSTRUCCIONS:
              <ul>
                <li>Situa’t dins de la teva parcel·la i observa-la.</li>
                <li>Mira si hi ha plantes i herbes. </li>
                <li>A continuació, contesta les següents preguntes sobre la presència de plantes i herbes i a quina categoria estarien.</li>
              </ul>
              PREGUNTA 2.2: Hi observeu plantes i herbes?
              <br />
            </Typography>
            <FormControl fullWidth>
              <InputLabel>Resposta</InputLabel>
              <Select
                value={formData.plantSiNo}
                onChange={handleChange('plantSiNo')}
                label="Resposta"
              >
                <MenuItem value="Si">Sí</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </FormControl>

            <Typography variant="body1" gutterBottom>
              <br />
              PREGUNTA 2.2: A quina categoria les podeu ubicar?
              <br />
            </Typography>
            <Select
              label="Categoria de Plantes"
              variant="outlined"
              fullWidth
              multiple
              value={formData.plantCategory}
              onChange={handleChange('plantCategory')}
              renderValue={(selected) => selected.join(', ')}
              sx={{ marginBottom: '1rem' }}
            >
              <MenuItem value="Herbes gramínies">
                <Checkbox checked={formData.plantCategory.indexOf('Herbes gramínies') > -1} />
                <ListItemText primary="Herbes gramínies" />
              </MenuItem>
              <MenuItem value="Plantes de flor petita">
                <Checkbox checked={formData.plantCategory.indexOf('Plantes de flor petita') > -1} />
                <ListItemText primary="Plantes de flor petita (herbes anuals)" />
              </MenuItem>
              <MenuItem value="Plantes rastreres">
                <Checkbox checked={formData.plantCategory.indexOf('Plantes rastreres') > -1} />
                <ListItemText primary="Plantes rastreres (plantes tapissants)" />
              </MenuItem>
              <MenuItem value="Males herbes">
                <Checkbox checked={formData.plantCategory.indexOf('Males herbes') > -1} />
                <ListItemText primary="Males herbes (plantes invasores)" />
              </MenuItem>
              <MenuItem value="Falgueres">
                <Checkbox checked={formData.plantCategory.indexOf('Falgueres') > -1} />
                <ListItemText primary="Falgueres" />
              </MenuItem>
              <MenuItem value="Musgs">
                <Checkbox checked={formData.plantCategory.indexOf('Musgs') > -1} />
                <ListItemText primary="Musgs" />
              </MenuItem>
              <MenuItem value="Plantes de flor vistosa">
                <Checkbox checked={formData.plantCategory.indexOf('Plantes de flor vistosa') > -1} />
                <ListItemText primary="Plantes de flor vistosa (herbes perennes o bianuals)" />
              </MenuItem>
              <MenuItem value="Arbustos petits o juvenils">
                <Checkbox checked={formData.plantCategory.indexOf('Arbustos petits o juvenils') > -1} />
                <ListItemText primary="Arbustos petits o juvenils" />
              </MenuItem>
            </Select>
          </>
        );

      case 2:
        return (
          <>
            <Typography variant="h4" component="h2" align="center" gutterBottom>
              <br />
              <br />
              ARBRES!!
              <br />
              <br />
            </Typography>
            <Typography variant="body1" gutterBottom>
              Ara és el torn dels arbres. Anem a observar-los i classificar-los.
              <br />
              INSTRUCCIONS:
              <ul>
                <li>Situa’t dins de la teva parcel·la i observa-la.</li>
                <li>Mira si hi ha restes orgàniques. Per ajudar-te pots consultar la guia que et vam oferir a l’aula. </li>
                <li>A continuació contesta les següents preguntes sobre la presencia de restes orgàniques i a quina categoria
                  estarien. Adjunta les fotografies.</li>
              </ul>
              <br />
              PREGUNTA 2.3: Hi observeu arbres?
              <br />
            </Typography>
            <FormControl fullWidth>
              <InputLabel>Resposta</InputLabel>
              <Select
                value={formData.arbreSiNo}
                onChange={handleChange('arbreSiNo')}
                label="Resposta"
              >
                <MenuItem value="Si">Sí</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </FormControl>

            <Typography variant="body1" gutterBottom>
              <br />
              PREGUNTA 2.3: A quina categoria els podeu ubicar?
              <br />
            </Typography>
            <Select
              label="Categoria d'Arbres"
              variant="outlined"
              fullWidth
              multiple
              value={formData.treeCategory}
              onChange={handleChange('treeCategory')}
              renderValue={(selected) => selected.join(', ')}
              sx={{ marginBottom: '1rem' }}
            >
              <MenuItem value="Arbres caducifolis">
                <Checkbox checked={formData.treeCategory.indexOf('Arbres caducifolis') > -1} />
                <ListItemText primary="Arbres caducifolis (de fulla caduca): com el roure, faig, plataner" />
              </MenuItem>
              <MenuItem value="Arbres perennifolis">
                <Checkbox checked={formData.treeCategory.indexOf('Arbres perennifolis') > -1} />
                <ListItemText primary="Arbres perennifolis (de fulla perenne): com el pi, alzina, olivera" />
              </MenuItem>
              <MenuItem value="Arbres conífers">
                <Checkbox checked={formData.treeCategory.indexOf('Arbres conífers') > -1} />
                <ListItemText primary="Arbres conífers: com el pi, avet, cedre" />
              </MenuItem>
              <MenuItem value="Arbres fruiters">
                <Checkbox checked={formData.treeCategory.indexOf('Arbres fruiters') > -1} />
                <ListItemText primary="Arbres fruiters: com la pomera, taronger, pruner" />
              </MenuItem>
              <MenuItem value="Arbres ornamentals">
                <Checkbox checked={formData.treeCategory.indexOf('Arbres ornamentals') > -1} />
                <ListItemText primary="Arbres ornamentals: com la magnòlia, ciruera de jardí, jacaranda" />
              </MenuItem>
              <MenuItem value="Arbres autòctons">
                <Checkbox checked={formData.treeCategory.indexOf('Arbres autòctons') > -1} />
                <ListItemText primary="Arbres autòctons (natius): com l'alzina, roure, lledoner" />
              </MenuItem>
              <MenuItem value="Arbres exòtics">
                <Checkbox checked={formData.treeCategory.indexOf('Arbres exòtics') > -1} />
                <ListItemText primary="Arbres exòtics (no autòctons): com l'eucaliptus, palmera, ailant" />
              </MenuItem>
            </Select>
          </>
        );

      case 3:
        return (
          <>
            <Typography variant="h4" component="h2" align="center" gutterBottom>
              <br />
              <br />
              RESTES ORGÀNIQUES!!
              <br />
              <br />
            </Typography>
            <Typography variant="body1" gutterBottom>
              Per finalitzar l’apartat de vegetació, ara buscarem restes orgàniques..
              <br />
              INSTRUCCIONS:
              <ul>
                <li>Situa’t dins de la teva parcel·la i observa-la.</li>
                <li>Mira si hi ha restes orgàniques. Per ajudar-te pots consultar la guia que et vam oferir a l’aula. </li>
                <li>A continuació contesta les següents preguntes sobre la presencia de restes orgàniques i a quina categoria
                  estarien. Adjunta les fotografies.</li>
              </ul>
              <br />
              PREGUNTA 2.4: Hi podeu observar restes orgàniques?
              <br />
            </Typography>
            <FormControl fullWidth>
              <InputLabel>Resposta</InputLabel>
              <Select
                value={formData.restorg}
                onChange={handleChange('restorg')}
                label="Resposta"
              >
                <MenuItem value="Si">Sí</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </FormControl>

            <Typography variant="body1" gutterBottom>
              <br />
              PREGUNTA 2.4: A quina categoria les podeu ubicar?
              <br />
            </Typography>
            <Select
              label="Categoria de Restes Orgàniques"
              variant="outlined"
              fullWidth
              multiple
              value={formData.organicCategory}
              onChange={handleChange('organicCategory')}
              renderValue={(selected) => selected.join(', ')}
              sx={{ marginBottom: '1rem' }}
            >
              <MenuItem value="Fulles caigudes">
                <Checkbox checked={formData.organicCategory.indexOf('Fulles caigudes') > -1} />
                <ListItemText primary="Fulles caigudes" />
              </MenuItem>
              <MenuItem value="Branques i tiges petites">
                <Checkbox checked={formData.organicCategory.indexOf('Branques i tiges petites') > -1} />
                <ListItemText primary="Branques i tiges petites" />
              </MenuItem>
              <MenuItem value="Flors o fruits en descomposició">
                <Checkbox checked={formData.organicCategory.indexOf('Flors o fruits en descomposició') > -1} />
                <ListItemText primary="Flors o fruits en descomposició" />
              </MenuItem>
              <MenuItem value="Herba tallada o morta">
                <Checkbox checked={formData.organicCategory.indexOf('Herba tallada o morta') > -1} />
                <ListItemText primary="Herba tallada o morta" />
              </MenuItem>
              <MenuItem value="Restes de fruits secs o llavors">
                <Checkbox checked={formData.organicCategory.indexOf('Restes de fruits secs o llavors') > -1} />
                <ListItemText primary="Restes de fruits secs o llavors" />
              </MenuItem>
              <MenuItem value="Matèria orgànica en descomposició">
                <Checkbox checked={formData.organicCategory.indexOf('Matèria orgànica en descomposició') > -1} />
                <ListItemText primary="Matèria orgànica en descomposició (humus)" />
              </MenuItem>
              <MenuItem value="Restes d'animals petits o excrements">
                <Checkbox checked={formData.organicCategory.indexOf("Restes d'animals petits o excrements") > -1} />
                <ListItemText primary="Restes d'animals petits o excrements" />
              </MenuItem>
              <MenuItem value="Miceli de fongs">
                <Checkbox checked={formData.organicCategory.indexOf('Miceli de fongs') > -1} />
                <ListItemText primary="Miceli de fongs" />
              </MenuItem>
            </Select>
          </>
        );

      case 4:
        return (
          <>
            <Typography variant="h4" component="h2" align="center" gutterBottom>
              <br />
              <br />
              NIVELL ASSOLIT!!
              <br />
              <br />
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
              <video width="600" controls>
                {/* Aquí se puede agregar el video si es necesario */}
              </video>
            </Box>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <Box className="box-content">
      <Box className="banner-container">
        <img src={BannerImage} alt="Banner" className="banner-image" />
        <br />
        <br />
      </Box>
      {renderSlideContent()}
      <Box sx={{ marginTop: '2rem', display: 'flex', justifyContent: 'space-between' }}>
        {currentSlide > 0 && (
          <Button variant="outlined" onClick={handlePrevious}>
            Anterior
          </Button>
        )}
        <Button variant="outlined" component={Link} to="/inici">
          Tornar a l'inici
        </Button>
        <Button variant="contained" onClick={handleNext}>
          {currentSlide < 4 ? 'Següent' : 'Enviar Dades'}
        </Button>
      </Box>
      {message && (
        <Typography variant="body2" color="error" align="center" sx={{ marginTop: '1rem' }}>
          {message}
        </Typography>
      )}
    </Box>
  );
};

export default Bloc2Page;
