import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Select, FormControl, InputLabel, MenuItem, Checkbox, ListItemText } from '@mui/material';
import { useNavigate, useParams, Link } from 'react-router-dom';
import BannerImage from '../assets/banner-image.png';
import '../App.css';
import axios from 'axios';

const Bloc5Page = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Obtener el ID del documento desde la URL
  const [currentSlide, setCurrentSlide] = useState(0);
  const [formData, setFormData] = useState({
    trashBool: '',
    trashCategory: [],
  });
  const [photo, setPhoto] = useState(null);
  const [photoUrl, setPhotoUrl] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const isEdit = queryParams.get('edit') === 'true';
    const documentId = queryParams.get('id');

    if (isEdit && documentId) {
      const fetchData = async () => {
        try {
          const response = await axios.get(`https://soil.quanta-labs.com/form/pregunta_5/${documentId}`);
          setFormData(response.data.document || response.data);
        } catch (error) {
          console.error('Error al cargar los datos:', error);
          setMessage('Error al cargar los datos');
        }
      };

      fetchData();
    }
  }, [id]);

  const handleNext = () => {
    if (currentSlide < 1) {
      setCurrentSlide((prev) => prev + 1);
    } else {
      handleSubmit(); // Enviar datos al final del bloque
    }
  };

  const handlePrevious = () => {
    if (currentSlide > 0) {
      setCurrentSlide((prev) => prev - 1);
    }
  };

  const handleChange = (field) => (e) => {
    setFormData({
      ...formData,
      [field]: e.target.value,
    });
  };

  const handlePhotoChange = (e) => {
    const selectedFile = e.target.files[0];
    setPhoto(selectedFile);
  };

  const handleSubmit = async () => {
    try {
      const queryParams = new URLSearchParams(window.location.search);
      const isEdit = queryParams.get('edit') === 'true';
      const documentId = queryParams.get('id');

      const jsonData = {
        document: {
          trashBool: formData.trashBool,
          trashCategory: formData.trashCategory,
        }
      };

      let response;
      if (isEdit && documentId) {
        response = await axios.patch(
          `https://soil.quanta-labs.com/form/pregunta_5/${documentId}`,
          jsonData,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer test',
            }
          }
        );
      } else {
        const formDataToSend = new FormData();
        formDataToSend.append('document', JSON.stringify(jsonData.document));

        if (photo) {
          formDataToSend.append('file', photo, photo.name);
        }

        response = await axios.post(
          'https://soil.quanta-labs.com/form/pregunta_5/',
          formDataToSend,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: 'Bearer test',
            }
          }
        );
      }

      console.log('Respuesta de la API:', response.data);
      setMessage('Datos enviados correctamente');
      localStorage.setItem('updateFormList', Date.now());

      // Espera un poco antes de navegar para asegurar que los datos se han actualizado
      setTimeout(() => {
        navigate('/inici');
      }, 1000);
    } catch (error) {
      console.error('Error al enviar datos:', error);
      if (error.response) {
        console.error('Respuesta del servidor:', error.response.data);
        setMessage(`Error al enviar datos: ${JSON.stringify(error.response.data)}`);
      } else {
        console.error('Error:', error.message);
        setMessage('Error al enviar datos');
      }
    }
  };

  const renderSlideContent = () => {
    switch (currentSlide) {
      case 0:
        return (
          <>
            <Typography variant="h4" component="h2" align="center" gutterBottom>
              <br />
              <br />
              RESIDUS!!
              <br />
              <br />
            </Typography>
            <Typography variant="body1" gutterBottom>
              El bloc 5 està destinat a observar possibles residus.
              <br />
              INSTRUCCIONS:
              <ul>
                <li>Situat dins de la teva parcel·la i observa-la</li>
                <li>Agafa com a recurs uns guants i un pal o bastó. Aquests són per si de cas, però EN CAP CAS ELS RESIDUS S’HAN DE TOCAR.</li>
                <li>Mira si hi ha residus i si en trobes, de quin tipus són. Recorda que tens a la teva disposició la guia que et vam oferir a l’aula.</li>
                <li>A continuació contesta les següents preguntes sobre la presencia de residus i a quina categoria estarien. Adjunta les fotografies.</li>
              </ul>
              PREGUNTA 5.1: Hi podeu observar residus?
              <br />
            </Typography>

            <FormControl fullWidth variant="outlined" sx={{ marginBottom: '1rem' }}>
              <InputLabel>Resposta</InputLabel>
              <Select
                value={formData.trashBool}
                onChange={handleChange('trashBool')}
                label="Resposta"
              >
                <MenuItem value="si">Sí</MenuItem>
                <MenuItem value="no">No</MenuItem>
              </Select>
            </FormControl>

            <Typography variant="body1" gutterBottom>
              <br />
              PREGUNTA 5.1: A quina categoria els podeu ubicar?
              <br />
            </Typography>
            <FormControl fullWidth variant="outlined" sx={{ marginBottom: '1rem' }}>
              <InputLabel>Categoria de Residu</InputLabel>
              <Select
                multiple
                value={formData.trashCategory}
                onChange={handleChange('trashCategory')}
                renderValue={(selected) => selected.join(', ')}
                label="Categoria de Residu"
              >
                <MenuItem value="Residus orgànics">
                  <Checkbox checked={formData.trashCategory.indexOf("Residus orgànics") > -1} />
                  <ListItemText primary="Residus orgànics" />
                </MenuItem>
                <MenuItem value="Residus inorgànics">
                  <Checkbox checked={formData.trashCategory.indexOf("Residus inorgànics") > -1} />
                  <ListItemText primary="Residus inorgànics" />
                </MenuItem>
                <MenuItem value="Residus de paper i cartró">
                  <Checkbox checked={formData.trashCategory.indexOf("Residus de paper i cartró") > -1} />
                  <ListItemText primary="Residus de paper i cartró" />
                </MenuItem>
                <MenuItem value="Residus tèxtils">
                  <Checkbox checked={formData.trashCategory.indexOf("Residus tèxtils") > -1} />
                  <ListItemText primary="Residus tèxtils" />
                </MenuItem>
                <MenuItem value="Residus perillosos">
                  <Checkbox checked={formData.trashCategory.indexOf("Residus perillosos") > -1} />
                  <ListItemText primary="Residus perillosos" />
                </MenuItem>
                <MenuItem value="Residus sanitaris">
                  <Checkbox checked={formData.trashCategory.indexOf("Residus sanitaris") > -1} />
                  <ListItemText primary="Residus sanitaris" />
                </MenuItem>
                <MenuItem value="Residus voluminosos">
                  <Checkbox checked={formData.trashCategory.indexOf("Residus voluminosos") > -1} />
                  <ListItemText primary="Residus voluminosos" />
                </MenuItem>
              </Select>
            </FormControl>
            <br />
            <Box sx={{ marginTop: '2rem', display: 'flex', justifyContent: 'left', alignItems: 'center', gap: '1rem' }}>
              <Button variant="contained" component="label">
                Afegeix Foto
                <input type="file" hidden onChange={handlePhotoChange} />
              </Button>
              {photo ? (
                <Box sx={{ marginTop: '10px' }}>
                  <img
                    src={URL.createObjectURL(photo)}
                    alt="Preview"
                    style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }}
                  />
                </Box>
              ) : photoUrl && (
                <Box sx={{ marginTop: '10px' }}>
                  <img
                    src={photoUrl}
                    alt="Imagen previa"
                    style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }}
                  />
                </Box>
              )}
            </Box>
          </>
        );

      case 1:
        return (
          <>
            <Typography variant="h4" component="h2" align="center" gutterBottom>
              <br />
              <br />
              NIVELL ASSOLIT!!
              <br />
              <br />
              Passegant
              <br />
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
              <video width="600" controls>
                {/* Aquí se puede agregar el video si es necesario */}
              </video>
            </Box>
          </>
        );
    }
  };

  return (
    <Box className="box-content">
      <Box className="banner-container">
        <img src={BannerImage} alt="Banner" className="banner-image" />
        <br />
        <br />
      </Box>
      {renderSlideContent()}
      <Box sx={{ marginTop: '2rem', display: 'flex', justifyContent: 'space-between' }}>
        {currentSlide > 0 && (
          <Button variant="outlined" onClick={handlePrevious}>
            Anterior
          </Button>
        )}
        <Button variant="outlined" component={Link} to="/inici">
          Tornar a l'inici
        </Button>
        <Button variant="contained" onClick={handleNext}>
          {currentSlide < 1 ? 'Següent' : 'Enviar Dades'}
        </Button>
      </Box>
      {message && (
        <Typography variant="body2" color="error" align="center" sx={{ marginTop: '1rem' }}>
          {message}
        </Typography>
      )}
    </Box>
  );
};

export default Bloc5Page;
