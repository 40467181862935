import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, FormControl, InputLabel, MenuItem, Checkbox, ListItemText, Select } from '@mui/material';
import { useNavigate, useParams, Link } from 'react-router-dom';
import BannerImage from '../assets/banner-image.png';
import '../App.css';
import axios from 'axios';

const Bloc6Page = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [formData, setFormData] = useState({
    fungiBool: '',
    fungiType: [],
    lichenBool: '',
    lichenType: [],
    bacteriaBool: '',
    bacteriaType: [],
  });
  const [photo, setPhoto] = useState(null);
  const [message, setMessage] = useState('');

  // Cargar los datos si está en modo edición
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const isEdit = queryParams.get('edit') === 'true';
    const documentId = queryParams.get('id');

    if (isEdit && documentId) {
      const fetchData = async () => {
        try {
          const response = await axios.get(`https://soil.quanta-labs.com/form/pregunta_6/${documentId}`);
          setFormData(response.data);
        } catch (error) {
          console.error('Error al cargar los datos:', error);
        }
      };

      fetchData();
    }
  }, [id]);

  const handleNext = () => {
    if (currentSlide < 2) {
      setCurrentSlide((prev) => prev + 1);
    } else {
      handleSubmit();
    }
  };

  const handlePrevious = () => {
    if (currentSlide > 0) {
      setCurrentSlide((prev) => prev - 1);
    }
  };

  const handleChange = (field) => (e) => {
    const value = e.target.value;
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handlePhotoChange = (e) => {
    const selectedFile = e.target.files[0];
    setPhoto(selectedFile);
  };

  const handleSubmit = async () => {
    try {
      const queryParams = new URLSearchParams(window.location.search);
      const isEdit = queryParams.get('edit') === 'true';
      const documentId = queryParams.get('id');

      let formDataToSend;
      const headers = {
        accept: 'application/json',
        Authorization: 'Bearer test', // Reemplaza con tu token real si es necesario
      };

      if (photo) {
        // Si hay foto, usamos FormData
        formDataToSend = new FormData();
        formDataToSend.append('document', JSON.stringify(formData)); // Añadimos el documento completo
        formDataToSend.append('file', photo, photo.name); // Adjuntamos la foto
        headers['Content-Type'] = 'multipart/form-data'; // multipart/form-data si hay foto
      } else {
        // Si no hay foto, enviamos un JSON normal
        formDataToSend = {
          ...formData,
          document: 'Microorganism Data',
        };
        headers['Content-Type'] = 'application/json'; // Cambiamos a JSON si no hay foto
      }

      let response;
      if (isEdit && documentId) {
        // Si estamos en modo edición (PATCH)
        response = await axios.patch(
          `https://soil.quanta-labs.com/form/pregunta_6/${documentId}`,
          formDataToSend,
          { headers }
        );
      } else {
        // Si estamos creando un nuevo documento (POST)
        response = await axios.post(
          'https://soil.quanta-labs.com/form/pregunta_6/',
          formDataToSend,
          { headers }
        );
      }

      console.log('Respuesta de la API:', response.data);
      setMessage('Datos enviados correctamente');

      // Notificar a IniciPage para actualizar la tabla
      localStorage.setItem('updateFormList', Date.now());

      // Redirigir al inicio después de enviar los datos
      navigate('/inici');
    } catch (error) {
      console.error('Error al enviar datos:', error.response?.data || error.message);
      setMessage('Error al enviar datos');
    }
  };

  const renderSlideContent = () => {
    switch (currentSlide) {
      case 0:
        return (
          <>
            <Typography variant="h4" component="h2" align="center" gutterBottom>
              <br />
              <br />
              FONGS i LÍQUENS!!
              <br />
              <br />
            </Typography>
            <Typography variant="body1" gutterBottom>
              El bloc 6 està destinat a observar possibles microorganismes. És possible que alguns els observis al parc i
              d’altres els hagis d’observar a l’aula mitjançant microscopis.
              <br />
              INSTRUCCIONS:
              <ul>
                <li>Situa’t dins de la teva parcel·la. Busca en ella la presència de fongs i líquens</li>
                <li>Agafa com a recurs uns guants, lupa i un rasclet. El nostre objectiu és OBSERVAR, mai arrencar fongs ni
                  líquens.</li>
                <li>Mira si hi ha fongs o líquens i si en trobes, de quin tipus són. Recorda que tens a la teva disposició la guia
                  que et vam oferir a l’aula.</li>
                <li>A continuació contesta les següents preguntes sobre la presencia de fongs i líquens i a quina categoria
                  estarien. Recorda d’adjuntar les fotografies.</li>
              </ul>
              <br />
              PREGUNTA 6.1: Hi podeu observar algun fong?
              <br />
            </Typography>
            <FormControl fullWidth variant="outlined" sx={{ marginBottom: '1rem' }}>
              <InputLabel>Resposta</InputLabel>
              <Select
                value={formData.fungiBool}
                onChange={handleChange('fungiBool')}
                label="Resposta"
              >
                <MenuItem value="si">Sí</MenuItem>
                <MenuItem value="no">No</MenuItem>
              </Select>
            </FormControl>

            <Typography variant="body1" gutterBottom>
              <br />
              PREGUNTA 6.1: De quin tipus?
              <br />
            </Typography>
            <FormControl fullWidth variant="outlined" sx={{ marginBottom: '1rem' }}>
              <InputLabel>Tipus de Fong</InputLabel>
              <Select
                multiple
                value={formData.fungiType}
                onChange={handleChange('fungiType')}
                renderValue={(selected) => selected.join(', ')}
                label="Tipus de Fong"
              >
                <MenuItem value="Bolets">
                  <Checkbox checked={formData.fungiType.indexOf('Bolets') > -1} />
                  <ListItemText primary="Bolets (Xampinyó, Bolets de soca, Bolet de tinta)" />
                </MenuItem>
                <MenuItem value="Fongs de motlle">
                  <Checkbox checked={formData.fungiType.indexOf('Fongs de motlle') > -1} />
                  <ListItemText primary="Fongs de motlle (Motlle verd, Motlle negre)" />
                </MenuItem>
                <MenuItem value="Fongs micorízics">
                  <Checkbox checked={formData.fungiType.indexOf('Fongs micorízics') > -1} />
                  <ListItemText primary="Fongs micorízics" />
                </MenuItem>
                <MenuItem value="Fongs de putrefacció">
                  <Checkbox checked={formData.fungiType.indexOf('Fongs de putrefacció') > -1} />
                  <ListItemText primary="Fongs de putrefacció (Saprofítics)" />
                </MenuItem>
              </Select>
            </FormControl>
            <br />
            <Typography variant="body1" gutterBottom>
              <br />
              PREGUNTA 6.1: Hi podeu observar algun liquen?
              <br />
            </Typography>
            <FormControl fullWidth variant="outlined" sx={{ marginBottom: '1rem' }}>
              <InputLabel>Resposta</InputLabel>
              <Select
                value={formData.lichenBool}
                onChange={handleChange('lichenBool')}
                label="Resposta"
              >
                <MenuItem value="si">Sí</MenuItem>
                <MenuItem value="no">No</MenuItem>
              </Select>
            </FormControl>

            <Typography variant="body1" gutterBottom>
              <br />
              PREGUNTA 6.1: De quin tipus?
              <br />
            </Typography>
            <FormControl fullWidth variant="outlined" sx={{ marginBottom: '1rem' }}>
              <InputLabel>Tipus de Líquen</InputLabel>
              <Select
                multiple
                value={formData.lichenType}
                onChange={handleChange('lichenType')}
                renderValue={(selected) => selected.join(', ')}
                label="Tipus de Líquen"
              >
                <MenuItem value="Líquen crustós">
                  <Checkbox checked={formData.lichenType.indexOf('Líquen crustós') > -1} />
                  <ListItemText primary="Líquen crustós" />
                </MenuItem>
                <MenuItem value="Líquen foliaci">
                  <Checkbox checked={formData.lichenType.indexOf('Líquen foliaci') > -1} />
                  <ListItemText primary="Líquen foliaci" />
                </MenuItem>
                <MenuItem value="Líquen fruticulós">
                  <Checkbox checked={formData.lichenType.indexOf('Líquen fruticulós') > -1} />
                  <ListItemText primary="Líquen fruticulós" />
                </MenuItem>
              </Select>
            </FormControl>
            <br />
            <Box sx={{ marginTop: '2rem', display: 'flex', justifyContent: 'left', alignItems: 'center', gap: '1rem' }}>
              <Button variant="contained" component="label">
                Afegeix Foto
                <input type="file" hidden onChange={handlePhotoChange} />
              </Button>
              {photo && (
                <Box sx={{ marginTop: '10px' }}>
                  <img
                    src={URL.createObjectURL(photo)}
                    alt="Preview"
                    style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }}
                  />
                </Box>
              )}
            </Box>
          </>
        );

      case 1:
        return (
          <>
            <Typography variant="h4" component="h2" align="center" gutterBottom>
              <br />
              <br />
              BACTERIS!!
              <br />
              <br />
            </Typography>
            <Typography variant="body1" gutterBottom>
              Ens estem especialitzant en científics i el nivell puja i molt !!
              Anem a observar la possible presència de bacteris en la nostra parcel·la.
              <br />
              INSTRUCCIONS:
              <ul>
                <li>Situa’t dins de la teva parcel·la. Busca en ella la presència de bacteris</li>
                <li>Agafa com a recurs una pala petita, uns guants, lupa i un rasclet.</li>
                <li>Fes un petit forat i recull terra en una bossa. Cava 5 centímetres més avall i torna a recollir i per últim cava
                  5 centímetres més avall i torna a agafar terra en una altra bossa. Recull les 4 mostres de terra de diferents
                  fondaries.</li>
                <li>Per a fer-ho començarem per mesurar la concentració de nitrits i nitrats en el sòl a través de kits de prova
                  de sòl que mesuri els nivells de nitrits i nitrats. Segueix les instruccions que t’especifiquem aquí.</li>
                <li>Observa si hi ha fulles i restes orgàniques en descomposició. En cas afirmatiu recull una mostra en una
                  bossa per analitzar-les a l’aula seguint les instruccions que hi ha aquí</li>
                <li>A continuació contesta les següents preguntes sobre la presencia de fongs i líquens i a quina categoria
                  estarien. Recorda d’adjuntar les fotografies.</li>
              </ul>

              <br />
              PREGUNTA 6.2: Després de fer els experiments, hi podeu observar algun bacteri?
              <br />
            </Typography>
            <FormControl fullWidth variant="outlined" sx={{ marginBottom: '1rem' }}>
              <InputLabel>Resposta</InputLabel>
              <Select
                value={formData.fungiBool}
                onChange={handleChange('fungiBool')}
                label="Resposta"
              >
                <MenuItem value="si">Sí</MenuItem>
                <MenuItem value="no">No</MenuItem>
              </Select>
            </FormControl>

            <Typography variant="body1" gutterBottom>
              <br />
              PREGUNTA 6.2: De quin tipus?
              <br />
            </Typography>
            <FormControl fullWidth variant="outlined" sx={{ marginBottom: '1rem' }}>
              <InputLabel>Tipus de Bacteri</InputLabel>
              <Select
                multiple
                value={formData.bacteriaType}
                onChange={handleChange('bacteriaType')}
                renderValue={(selected) => selected.join(', ')}
                label="Tipus de Bacteri"
              >
                <MenuItem value="Bacteris nitrificants">
                  <Checkbox checked={formData.bacteriaType.indexOf('Bacteris nitrificants') > -1} />
                  <ListItemText primary="Bacteris nitrificants" />
                </MenuItem>
                <MenuItem value="Bacteris descomponedors">
                  <Checkbox checked={formData.bacteriaType.indexOf('Bacteris descomponedors') > -1} />
                  <ListItemText primary="Bacteris descomponedors" />
                </MenuItem>
                <MenuItem value="Cianobacteris">
                  <Checkbox checked={formData.bacteriaType.indexOf('Cianobacteris') > -1} />
                  <ListItemText primary="Cianobacteris" />
                </MenuItem>
              </Select>
            </FormControl>
            <br />
            <Box sx={{ marginTop: '2rem', display: 'flex', justifyContent: 'left', alignItems: 'center', gap: '1rem' }}>
              <Button variant="contained" component="label">
                Afegeix Foto
                <input type="file" hidden onChange={handlePhotoChange} />
              </Button>
              {photo && (
                <Box sx={{ marginTop: '10px' }}>
                  <img
                    src={URL.createObjectURL(photo)}
                    alt="Preview"
                    style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }}
                  />
                </Box>
              )}
            </Box>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Box className="box-content">
      <Box className="banner-container">
        <img src={BannerImage} alt="Banner" className="banner-image" />
        <br />
        <br />
      </Box>
      {renderSlideContent()}
      <Box sx={{ marginTop: '2rem', display: 'flex', justifyContent: 'space-between' }}>
        {currentSlide > 0 && (
          <Button variant="outlined" onClick={handlePrevious}>
            Anterior
          </Button>
        )}
        <Button variant="outlined" component={Link} to="/inici">
          Tornar a l'inici
        </Button>
        <Button variant="contained" onClick={handleNext}>
          {currentSlide < 2 ? 'Següent' : 'Enviar Dades'}
        </Button>
      </Box>
      {message && (
        <Typography variant="body2" color="error" align="center" sx={{ marginTop: '1rem' }}>
          {message}
        </Typography>
      )}
    </Box>
  );
};

export default Bloc6Page;
