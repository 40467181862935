import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Checkbox,
  ListItemText,
  Select,
  TextField
} from '@mui/material';
import { useNavigate, useParams, Link } from 'react-router-dom';
import BannerImage from '../assets/banner-image.png';
import '../App.css';
import axios from 'axios';

const Bloc7Page = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [formData, setFormData] = useState({
    waterBool: '',
    waterSource: [],
    waterFlow: '',
    waterMovement: [],
    waterColor: [],
    waterTransparency: '',
    waterTransparencyLevel: [],
    waterTemperature: '',
    waterTemperatureCategory: [],
    waterPH: '',
    waterPHCategory: [],
    aquaticAnimalBool: '',
    aquaticAnimalCategory: [],
    aquaticPlantBool: '',
    aquaticPlantCategory: [],
  });
  const [photo, setPhoto] = useState(null);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const isEdit = queryParams.get('edit') === 'true';
    const documentId = queryParams.get('id');

    if (isEdit && documentId) {
      const fetchData = async () => {
        try {
          const response = await axios.get(`https://soil.quanta-labs.com/form/pregunta_7/${documentId}`);
          setFormData(response.data.document || response.data);
        } catch (error) {
          console.error('Error al cargar los datos:', error);
          setMessage('Error al cargar los datos');
        }
      };

      fetchData();
    }
  }, [id]);

  const handleNext = () => {
    if (currentSlide < 9) {
      setCurrentSlide((prev) => prev + 1);
    } else {
      handleSubmit();
    }
  };

  const handlePrevious = () => {
    if (currentSlide > 0) {
      setCurrentSlide((prev) => prev - 1);
    }
  };

  const handleChange = (field) => (e) => {
    setFormData({
      ...formData,
      [field]: e.target.value,
    });
  };

  const handlePhotoChange = (e) => {
    const selectedFile = e.target.files[0];
    setPhoto(selectedFile);
  };

  const handleSubmit = async () => {
    try {
      const queryParams = new URLSearchParams(window.location.search);
      const isEdit = queryParams.get('edit') === 'true';
      const documentId = queryParams.get('id');

      const jsonData = {
        document: {
          waterBool: formData.waterBool,
          waterSource: formData.waterSource,
          waterFlow: formData.waterFlow,
          waterMovement: formData.waterMovement,
          waterColor: formData.waterColor,
          waterTransparency: formData.waterTransparency,
          waterTransparencyLevel: formData.waterTransparencyLevel,
          waterTemperature: formData.waterTemperature,
          waterTemperatureCategory: formData.waterTemperatureCategory,
          waterPH: formData.waterPH,
          waterPHCategory: formData.waterPHCategory,
          aquaticAnimalBool: formData.aquaticAnimalBool,
          aquaticAnimalCategory: formData.aquaticAnimalCategory,
          aquaticPlantBool: formData.aquaticPlantBool,
          aquaticPlantCategory: formData.aquaticPlantCategory,
        }
      };

      let response;
      if (isEdit && documentId) {
        response = await axios.patch(
          `https://soil.quanta-labs.com/form/pregunta_7/${documentId}`,
          jsonData,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer test',
            }
          }
        );
      } else {
        const formDataToSend = new FormData();
        formDataToSend.append('document', JSON.stringify(jsonData.document));

        if (photo) {
          formDataToSend.append('file', photo, photo.name);
        }

        response = await axios.post(
          'https://soil.quanta-labs.com/form/pregunta_7/',
          formDataToSend,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: 'Bearer test',
            }
          }
        );
      }

      console.log('Respuesta de la API:', response.data);
      setMessage('Datos enviados correctamente');
      localStorage.setItem('updateFormList', Date.now());

      // Espera un poco antes de navegar para asegurar que los datos se han actualizado
      setTimeout(() => {
        navigate('/inici');
      }, 1000);
    } catch (error) {
      console.error('Error al enviar datos:', error);
      if (error.response) {
        console.error('Respuesta del servidor:', error.response.data);
        setMessage(`Error al enviar datos: ${JSON.stringify(error.response.data)}`);
      } else {
        console.error('Error:', error.message);
        setMessage('Error al enviar datos');
      }
    }
  };


  const renderSelectWithCheckbox = (field, options, label) => (
    <FormControl fullWidth variant="outlined" sx={{ marginBottom: '1rem' }}>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        value={formData[field]}
        onChange={(e) => handleChange(field)(e)}
        renderValue={(selected) => selected.join(', ')}
        label={label}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            <Checkbox checked={formData[field].indexOf(option) > -1} />
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const renderSlideContent = () => {
    switch (currentSlide) {
      case 0:
        return (
          <>
            <Typography variant="h4" align="center" gutterBottom>
              <br />
              <br />
              AIGUA!!
              <br />
              <br />
            </Typography>
            <Typography variant="body1" gutterBottom>
              Ens endinsem en l’últim apartat del challenge!! Ho esteu aconseguint!! Per aquest apartat, no ens centrarem en
              la parcel·la en la que hem estat treballant fins ara, sinó en el parc sencer.
              <br />
              INSTRUCCIONS:
              <ul>
                <li>Situa’t en el parc. Busca dins d’aquest algun tipus d’aigua</li>
                <li>Recorda que tens aquí el recurs que t’hem ofert a l’aula.</li>
              </ul>
              <br />
              PREGUNTA 7.1: Hi podeu observar aigua?
              <br />
              <br />
            </Typography>
            <FormControl fullWidth variant="outlined" sx={{ marginBottom: '1rem' }}>
              <InputLabel>Resposta</InputLabel>
              <Select
                value={formData.waterBool}
                onChange={handleChange('waterBool')}
                label="Resposta"
              >
                <MenuItem value="si">Sí</MenuItem>
                <MenuItem value="no">No</MenuItem>
              </Select>
            </FormControl>
            <br />
            <Box sx={{ marginTop: '2rem', display: 'flex', justifyContent: 'left', alignItems: 'center', gap: '1rem' }}>
              <Button variant="contained" component="label">
                Afegeix Foto
                <input type="file" hidden onChange={handlePhotoChange} />
              </Button>
              {photo && (
                <Box sx={{ marginTop: '10px' }}>
                  <img
                    src={URL.createObjectURL(photo)}
                    alt="Preview"
                    style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }}
                  />
                </Box>
              )}
            </Box>
          </>
        );
      case 1:
        return (
          <>
            <Typography variant="h4" align="center" gutterBottom>
              <br />
              <br />
              PROCEDÈNCIA AIGUA!!
              <br />
              <br />
            </Typography>
            <Typography variant="body1" gutterBottom>
              A continuació anem a veure d’on prové aquesta aigua que hem trobat.
              <br />
              INSTRUCCIONS:
              <ul>
                <li>Situa’t en el parc. Busca el punt d’aigua que has triat.</li>
                <li>Segueix la veta de l’aigua fins que puguis determinar d’on prové</li>
                <li>Recorda que tens aquí el recurs que t’hem ofert a l’aula.</li>
              </ul>
              <br />
              PREGUNTA 7.2: D’on prové l’aigua que heu observat al parc?
              <br />
              <br />
            </Typography>
            {renderSelectWithCheckbox('waterSource', [
              'Aigua de pluja',
              'Aigua subterrània',
              'Aigua d’un riu o rierol',
              'Aigua de fonts artificials',
              'Aigua acumulada en estanys o llacs artificials',
              'Aigua de reg o sistemes de drenatge',
            ], "Font d'Aigua")}
            <br />
            <Box sx={{ marginTop: '2rem', display: 'flex', justifyContent: 'left', alignItems: 'center', gap: '1rem' }}>
              <Button variant="contained" component="label">
                Afegeix Foto
                <input type="file" hidden onChange={handlePhotoChange} />
              </Button>
              {photo && (
                <Box sx={{ marginTop: '10px' }}>
                  <img
                    src={URL.createObjectURL(photo)}
                    alt="Preview"
                    style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }}
                  />
                </Box>
              )}
            </Box>
          </>
        );
      case 2:
        return (
          <>
            <Typography variant="h4" align="center" gutterBottom>
              <br />
              <br />
              CIRCULACIÓ AIGUA!!
              <br />
              <br />
            </Typography>
            <Typography variant="body1" gutterBottom>
              Ara que ja sabem la seva procedència, cal analitzar si circula.
              <br />
              INSTRUCCIONS:
              <ul>
                <li>Situa’t en el parc. Busca el punt d’aigua que has triat.</li>
                <li>Observa si l’aigua circula o està estancada. En cas que circuli, determina quin tipus de circulació realitza.</li>
                <li>Recorda que tens aquí el recurs que t’hem ofert a l’aula.</li>
              </ul>
              <br />
              PREGUNTA 7.3: L’aigua del parc que esteu analitzant circula?
              <br />
            </Typography>
            <FormControl fullWidth variant="outlined" sx={{ marginBottom: '1rem' }}>
              <InputLabel>Resposta</InputLabel>
              <Select
                value={formData.waterFlow}
                onChange={handleChange('waterFlow')}
                label="Resposta"
              >
                <MenuItem value="si">Sí</MenuItem>
                <MenuItem value="no">No</MenuItem>
              </Select>
            </FormControl>
            <Typography variant="body1" gutterBottom>
              <br />
              PREGUNTA 7.3: Quin moviment fa l’aigua?
              <br />
            </Typography>
            {renderSelectWithCheckbox('waterMovement', [
              'Infiltració',
              'Percolació',
              'Capil·laritat',
            ], "Moviment de l'Aigua")}
            <br />
            <Box sx={{ marginTop: '2rem', display: 'flex', justifyContent: 'left', alignItems: 'center', gap: '1rem' }}>
              <Button variant="contained" component="label">
                Afegeix Foto
                <input type="file" hidden onChange={handlePhotoChange} />
              </Button>
              {photo && (
                <Box sx={{ marginTop: '10px' }}>
                  <img
                    src={URL.createObjectURL(photo)}
                    alt="Preview"
                    style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }}
                  />
                </Box>
              )}
            </Box>
          </>
        );
      case 3:
        return (
          <>
            <Typography variant="h4" align="center" gutterBottom>
              <br />
              <br />
              COLOR AIGUA!!
              <br />
              <br />
            </Typography>
            <Typography variant="body1" gutterBottom>
              Anem a analitzar ara el color de l’aigua que observeu..
              <br />
              INSTRUCCIONS:
              <ul>
                <li>Situa’t en el parc. Busca el punt d’aigua que has triat.</li>
                <li>Observa quin color té l’aigua. Per a realitzar-ho, agafa una ampolla petita transparent i ompla-la de l’aigua
                  que estàs observant. Quan la tinguis plena situa un full blanc darrera i mira el seu color. IMPORTANT!!
                  Quan acabeu no la llenceu</li>
                <li>Recorda que tens aquí el recurs que t’hem ofert a l’aula.</li>
              </ul>
              <br />
              PREGUNTA 7.4: L’aigua del parc que esteu analitzant quin color té?
              <br />
            </Typography>
            {renderSelectWithCheckbox('waterColor', [
              'Aigua clara',
              'Aigua marronosa o groguenca',
              'Aigua verda',
              'Aigua negra o molt fosca',
              'Aigua amb tons rogencs o ataronjats',
            ], "Color de l'Aigua")}
            <br />
            <Box sx={{ marginTop: '2rem', display: 'flex', justifyContent: 'left', alignItems: 'center', gap: '1rem' }}>
              <Button variant="contained" component="label">
                Afegeix Foto
                <input type="file" hidden onChange={handlePhotoChange} />
              </Button>
              {photo && (
                <Box sx={{ marginTop: '10px' }}>
                  <img
                    src={URL.createObjectURL(photo)}
                    alt="Preview"
                    style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }}
                  />
                </Box>
              )}
            </Box>
          </>
        );
      case 4:
        return (
          <>
            <Typography variant="h4" align="center" gutterBottom>
              <br />
              <br />
              TRANSPARÈNCIA AIGUA!!
              <br />
              <br />
            </Typography>
            <Typography variant="body1" gutterBottom>
              Després d’analitzar el color de l’aigua que observeu, aprofitarem per analitzar la seva transparència
              <br />
              INSTRUCCIONS:
              <ul>
                <li>Situa’t en el parc. Busca el punt d’aigua que has triat.</li>
                <li>Agafa l’ampolla de la pràctica anterior i situa-la davant del full blanc. Determina si pots veure el full.</li>
                <li>Recorda que tens aquí el recurs que t’hem ofert a l’aula.</li>
              </ul>
              <br />
              PREGUNTA 7.5: L’aigua que observeu al parc, és transparenta?
              <br />
            </Typography>
            <FormControl fullWidth variant="outlined" sx={{ marginBottom: '1rem' }}>
              <InputLabel>Resposta</InputLabel>
              <Select
                value={formData.waterTransparency}
                onChange={handleChange('waterTransparency')}
                label="Resposta"
              >
                <MenuItem value="si">Sí</MenuItem>
                <MenuItem value="no">No</MenuItem>
              </Select>
            </FormControl>
            <Typography variant="body1" gutterBottom>
              <br />
              PREGUNTA 7.5: Quin grau de transparència té?
              <br />
            </Typography>
            {renderSelectWithCheckbox('waterTransparencyLevel', [
              'Aigua transparent',
              'Aigua lleugerament tèrbola',
              'Aigua molt tèrbola',
            ], "Grau de Transparència")}
            <br />
            <Box sx={{ marginTop: '2rem', display: 'flex', justifyContent: 'left', alignItems: 'center', gap: '1rem' }}>
              <Button variant="contained" component="label">
                Afegeix Foto
                <input type="file" hidden onChange={handlePhotoChange} />
              </Button>
              {photo && (
                <Box sx={{ marginTop: '10px' }}>
                  <img
                    src={URL.createObjectURL(photo)}
                    alt="Preview"
                    style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }}
                  />
                </Box>
              )}
            </Box>
          </>
        );
      case 5:
        return (
          <>
            <Typography variant="h4" align="center" gutterBottom>
              <br />
              <br />
              TEMPERATURA AIGUA!!
              <br />
              <br />
            </Typography>
            <Typography variant="body1" gutterBottom>
              Anem ara a analitzar la temperatura de l’aigua que estàs observant.
              <br />
              INSTRUCCIONS:
              <ul>
                <li>Situa’t en el parc. Busca el punt d’aigua que has triat.</li>
                <li>Agafa un termòmetre i submergeix-lo dins l’aigua. Espera 5 minuts i mira quina és la temperatura de
                  l’aigua.</li>
                <li>En cas que no hi hagi suficent profunditat d’aigua, buida l’aigua de les pràctiques anteriors i torna a ompli
                  l’ampolla. Immediatament introdueix el termòmetre i aguanta’l 5 minuts per determinar la temperatura..</li>
                <li>Recorda que tens aquí el recurs que t’hem ofert a l’aula.</li>
              </ul>
              <br />
              PREGUNTA 7.6: Quina temperatura té l’aigua del parc que esteu observant?
              <br />
            </Typography>
            <TextField
              label="Temperatura de l'Aigua (°C)"
              variant="outlined"
              fullWidth
              value={formData.waterTemperature}
              onChange={handleChange('waterTemperature')}
              type="number"
              sx={{ marginBottom: '1rem' }}
            />
            <Typography variant="body1" gutterBottom>
              <br />
              PREGUNTA 7.6: A quina categoria la podem ubicar?
              <br />
            </Typography>
            {renderSelectWithCheckbox('waterTemperatureCategory', [
              'Aigua freda (per sota de 15 °C)',
              'Aigua temperada (entre 15 °C i 25 °C)',
              'Aigua càlida (per sobre de 25 °C)',
            ], "Categoria de Temperatura")}
            <br />
            <Box sx={{ marginTop: '2rem', display: 'flex', justifyContent: 'left', alignItems: 'center', gap: '1rem' }}>
              <Button variant="contained" component="label">
                Afegeix Foto
                <input type="file" hidden onChange={handlePhotoChange} />
              </Button>
              {photo && (
                <Box sx={{ marginTop: '10px' }}>
                  <img
                    src={URL.createObjectURL(photo)}
                    alt="Preview"
                    style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }}
                  />
                </Box>
              )}
            </Box>
          </>
        );
      case 6:
        return (
          <>
            <Typography variant="h4" align="center" gutterBottom>
              <br />
              <br />
              pH de l’AIGUA!!
              <br />
              <br />
            </Typography>
            <Typography variant="body1" gutterBottom>
              A continuació anem a analitzar el grau de pH de l’aigua que estem observant
              <br />
              INSTRUCCIONS:
              <ul>
                <li>Situa’t en el parc. Busca el punt d’aigua que has triat.</li>
                <li>Agafa un comptegotes i omple’l amb l’aigua que vas a analitzar.</li>
                <li>A continuació agafa una tira per mesurar el pH de l’aigua i aplica unes gotes d’aigua del comptegotes a
                  sobre.</li>
                <li>Obseva quin color agafa la tira per mesurar el pH i determina quin és el grau d’acidesa que té l’aigua.</li>
                <li>Recorda que tens aquí el recurs que t’hem ofert a l’aula.</li>
              </ul>
              <br />
              PREGUNTA 7.7: Quin grau de pH té l’aigua del parc que esteu observant?
              <br />
            </Typography>
            <TextField
              label="Grau de pH de l'Aigua"
              variant="outlined"
              fullWidth
              value={formData.waterPH}
              onChange={handleChange('waterPH')}
              type="number"
              sx={{ marginBottom: '1rem' }}
            />
            <Typography variant="body1" gutterBottom>
              <br />
              PREGUNTA 7.7: A quina categoria la podem ubicar?
              <br />
            </Typography>
            {renderSelectWithCheckbox('waterPHCategory', [
              'pH per sota de 7',
              'pH igual a 7',
              'pH per sobre de 7',
            ], "Categoria de pH")}
            <br />
            <Box sx={{ marginTop: '2rem', display: 'flex', justifyContent: 'left', alignItems: 'center', gap: '1rem' }}>
              <Button variant="contained" component="label">
                Afegeix Foto
                <input type="file" hidden onChange={handlePhotoChange} />
              </Button>
              {photo && (
                <Box sx={{ marginTop: '10px' }}>
                  <img
                    src={URL.createObjectURL(photo)}
                    alt="Preview"
                    style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }}
                  />
                </Box>
              )}
            </Box>
          </>
        );
      case 7:
        return (
          <>
            <Typography variant="h4" align="center" gutterBottom>
              <br />
              <br />
              ANIMALS AQUÀTICS!!
              <br />
              <br />
            </Typography>
            <Typography variant="body1" gutterBottom>
              Seguim analitzant l’aigua del parc i concretament la fauna que hi habita.
              <br />
              INSTRUCCIONS:
              <ul>
                <li>Situa’t en el parc. Busca el punt d’aigua que has triat.</li>
                <li>Observa molt detalladament si hi ha algun petit animal aquàtic. Aquests poden estar a la suerfície de
                  l’aigua i dins la mateixa submergit</li>
                <li>Recorda que tens aquí el recurs que t’hem ofert a l’aula.</li>
              </ul>
              <br />
              PREGUNTA 7.8: A l’aigua del parc que esteu analitzant, podeu observar algun animal petit aquàtic?
              <br />
            </Typography>
            <FormControl fullWidth variant="outlined" sx={{ marginBottom: '1rem' }}>
              <InputLabel>Resposta</InputLabel>
              <Select
                value={formData.aquaticAnimalBool}
                onChange={handleChange('aquaticAnimalBool')}
                label="Resposta"
              >
                <MenuItem value="si">Sí</MenuItem>
                <MenuItem value="no">No</MenuItem>
              </Select>
            </FormControl>
            <Typography variant="body1" gutterBottom>
              <br />
              PREGUNTA 7.8: A quina categoria el podem ubicar?
              <br />
            </Typography>
            {renderSelectWithCheckbox('aquaticAnimalCategory', [
              'Insectes aquàtics',
              'Crustacis petits',
              'Mol·luscs petits',
              'Altres invertebrats',
            ], "Categoria d'Animal Aquàtic")}
            <br />
            <Box sx={{ marginTop: '2rem', display: 'flex', justifyContent: 'left', alignItems: 'center', gap: '1rem' }}>
              <Button variant="contained" component="label">
                Afegeix Foto
                <input type="file" hidden onChange={handlePhotoChange} />
              </Button>
              {photo && (
                <Box sx={{ marginTop: '10px' }}>
                  <img
                    src={URL.createObjectURL(photo)}
                    alt="Preview"
                    style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }}
                  />
                </Box>
              )}
            </Box>
          </>
        );
      case 8:
        return (
          <>
            <Typography variant="h4" align="center" gutterBottom>
              <br />
              <br />
              PLANTES AQUÀTIQUES!!
              <br />
              <br />
            </Typography>
            <Typography variant="body1" gutterBottom>
              Per acabar, analitzarem les plantes aquàtiques de l’aigua del parc.
              <br />
              INSTRUCCIONS:
              <ul>
                <li>Situa’t en el parc. Busca el punt d’aigua que has triat.</li>
                <li>Observa molt detalladament si hi ha alguna planta aquàtica. Aquestes poden estar a la suerfície de l’aigua i
                  dins la mateixa submergides.</li>
                <li>Recorda que tens aquí el recurs que t’hem ofert a l’aula.</li>
              </ul>
              <br />
              PREGUNTA 7.9: A l’aigua del parc que esteu analitzant, podeu observar alguna planta aquàtica?
              <br />
            </Typography>
            <FormControl fullWidth variant="outlined" sx={{ marginBottom: '1rem' }}>
              <InputLabel>Resposta</InputLabel>
              <Select
                value={formData.aquaticPlantBool}
                onChange={handleChange('aquaticPlantBool')}
                label="Resposta"
              >
                <MenuItem value="si">Sí</MenuItem>
                <MenuItem value="no">No</MenuItem>
              </Select>
            </FormControl>
            <Typography variant="body1" gutterBottom>
              <br />
              PREGUNTA 7.9: A quina categoria la podem ubicar?
              <br />
            </Typography>
            {renderSelectWithCheckbox('aquaticPlantCategory', [
              'Plantes submergides',
              'Plantes emergents',
              'Plantes flotants',
            ], "Categoria de Planta Aquàtica")}
            <br />
            <Box sx={{ marginTop: '2rem', display: 'flex', justifyContent: 'left', alignItems: 'center', gap: '1rem' }}>
              <Button variant="contained" component="label">
                Afegeix Foto
                <input type="file" hidden onChange={handlePhotoChange} />
              </Button>
              {photo && (
                <Box sx={{ marginTop: '10px' }}>
                  <img
                    src={URL.createObjectURL(photo)}
                    alt="Preview"
                    style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }}
                  />
                </Box>
              )}
            </Box>
          </>
        );

      case 9:
        return (
          <>
            <Typography variant="h4" component="h2" align="center" gutterBottom>
              <br />
              <br />
              NIVELL ASSOLIT!!
              <br />
              <br />
              Col·laborador Científic
              <br />
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
              <video width="600" controls>
                {/* Aquí se puede agregar el video si es necesario */}
              </video>
            </Box>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <Box className="box-content">
      <Box className="banner-container">
        <img src={BannerImage} alt="Banner" className="banner-image" />
        <br />
        <br />
      </Box>
      {renderSlideContent()}
      <Box sx={{ marginTop: '2rem', display: 'flex', justifyContent: 'space-between' }}>
        {currentSlide > 0 && (
          <Button variant="outlined" onClick={handlePrevious}>
            Anterior
          </Button>
        )}
        <Button variant="outlined" component={Link} to="/inici">
          Tornar a l'inici
        </Button>
        <Button variant="contained" onClick={handleNext}>
          {currentSlide < 9 ? 'Següent' : 'Enviar Dades'}
        </Button>
      </Box>
      {message && (
        <Typography variant="body2" color="error" align="center" sx={{ marginTop: '1rem' }}>
          {message}
        </Typography>
      )}
    </Box>
  );
};

export default Bloc7Page;
