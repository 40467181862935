import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Typography,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
} from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import BannerImage from '../assets/banner-image.png';
import '../App.css';

const API_BASE_URL = 'https://soil.quanta-labs.com/form';

const IniciPage = () => {
  const [formRows, setFormRows] = useState([]);
  const [blockProgress, setBlockProgress] = useState({});
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const getDateFromObjectId = (objectId) => {
    return new Date(parseInt(objectId.substring(0, 8), 16) * 1000).toLocaleString();
  };

  const fetchFormRows = useCallback(async () => {
    setIsLoading(true);
    try {
      const collectionsResponse = await axios.get(`${API_BASE_URL}/collections`);
      const collections = collectionsResponse.data;

      const formRowsPromises = collections.map(async (collection) => {
        try {
          const idsResponse = await axios.get(`${API_BASE_URL}/${collection}/document-ids`);
          const documentIds = idsResponse.data;

          const documentDetailsPromises = documentIds.map(async (id) => {
            try {
              const documentResponse = await axios.get(`${API_BASE_URL}/${collection}/${id}`);
              const documentData = documentResponse.data;

              return {
                id,
                collection,
                data: getDateFromObjectId(documentData._id),
                estat: documentData.status || 'Enviat',
              };
            } catch (error) {
              if (error.response && error.response.status === 404) {
                console.log(`Document ${id} in collection ${collection} not found, skipping.`);
                return null;
              }
              throw error;
            }
          });

          const results = await Promise.all(documentDetailsPromises);
          return results.filter(result => result !== null);
        } catch (error) {
          console.error(`Error fetching documents for collection ${collection}:`, error);
          return [];
        }
      });

      const resolvedFormRows = await Promise.all(formRowsPromises);
      const validRows = resolvedFormRows.flat().filter(row => row !== null);
      setFormRows(validRows);
      setMessage('');
    } catch (error) {
      console.error('Error fetching form rows:', error);
      setMessage('Error al obtener los formularios. Verifica la conexión o si los formularios existen.');
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    const savedProgress = JSON.parse(localStorage.getItem('blockProgress')) || {};
    setBlockProgress(savedProgress);

    fetchFormRows();

    const intervalId = setInterval(fetchFormRows, 30000);
    return () => clearInterval(intervalId);
  }, [fetchFormRows]);

  const handleStartBlock = (blockId) => {
    navigate(`/bloque/${blockId}`);
  };

  const handleDeleteForm = async (collection, id) => {
    try {
      await axios.delete(`${API_BASE_URL}/${collection}/${id}`);
      setFormRows((prevRows) => prevRows.filter((row) => row.id !== id));
      setMessage('Formulario eliminado correctamente.');
    } catch (error) {
      console.error('Error deleting form:', error);
      setMessage('Error al eliminar el formulario.');
    }
  };

  const bloques = [
    { id: 0, titulo: 'BLOC 0: Informació general' },
    { id: 1, titulo: 'BLOC 1: Tipus de sòl' },
    { id: 2, titulo: 'BLOC 2: Vegetació' },
    { id: 3, titulo: 'BLOC 3: Pedres, roques i minerals' },
    { id: 4, titulo: 'BLOC 4: Animals petits o insectes' },
    { id: 5, titulo: 'BLOC 5: Residus' },
    { id: 6, titulo: 'BLOC 6: Microorganismes' },
    { id: 7, titulo: 'BLOC 7: Aigua' },
  ];

  return (
    <Box sx={{ padding: '16px', backgroundColor: 'white', minHeight: '100vh', fontFamily: 'Arial, sans-serif' }}>
      <Box sx={{ textAlign: 'center', marginBottom: '16px' }}>
        <img src={BannerImage} alt="Banner" className="banner-image" style={{ width: '100%', maxHeight: '300px' }} />
        <br />
        <br />
      </Box>

      <Typography variant="h5" component="h4" gutterBottom align="center" className="subtitulo-inici" sx={{ my: 4 }}>
        <br />
        Segueix les indicacions per a recollir les dades dels elements ambientals.
        El procés és llarg però som científics i som rigoroses i rigorosos.
      </Typography>

      {message && (
        <Typography variant="body1" color="error" sx={{ marginBottom: '16px' }}>
          {message}
        </Typography>
      )}

      <Box sx={{ marginBottom: '1.5rem', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '16px' }}>
        {bloques.map((bloque) => (
          <Box key={bloque.id} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '70%' }}>
            <Typography sx={{ fontFamily: 'Arial, sans-serif' }}>{bloque.titulo}</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {blockProgress[bloque.id] && (
                <CheckCircle color="success" sx={{ marginRight: '8px' }} />
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleStartBlock(bloque.id)}
                sx={{ borderRadius: '10px', fontFamily: 'Arial, sans-serif' }}
              >
                {blockProgress[bloque.id] ? 'Completado' : 'Començar'}
              </Button>
            </Box>
          </Box>
        ))}
      </Box>

      <Box sx={{ overflow: 'auto', maxHeight: '600px', marginBottom: '2rem' }}>
        <TableContainer component={Paper} sx={{ width: '100%' }}>
          <Table aria-label="formularios table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Bloc</TableCell>
                <TableCell align="center">Data</TableCell>
                <TableCell align="center">Estat</TableCell>
                <TableCell align="center">Accions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell align="center" colSpan={4}>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : formRows.length > 0 ? (
                formRows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell align="center">{`Bloc ${row.collection.split('_')[1]}`}</TableCell>
                    <TableCell align="center">{row.data}</TableCell>
                    <TableCell align="center">{row.estat}</TableCell>
                    <TableCell align="center">
                      <Button
                        variant="outlined"
                        color="primary"
                        component={Link}
                        to={`/bloque/${row.collection.split('_')[1]}?edit=true&id=${row.id}`}
                        sx={{ marginRight: '8px' }}
                      >
                        Editar
                      </Button>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => handleDeleteForm(row.collection, row.id)}
                      >
                        Eliminar
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={4}>
                    No se han encontrado formularios. Es posible que hayan sido eliminados o no existan.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default IniciPage;