import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, TextField, Select, MenuItem, Checkbox, ListItemText } from '@mui/material';
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom';
import BannerImage from '../assets/banner-image.png';
import '../App.css';
import axios from 'axios';

const Bloc1Page = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams(); // Obtener el ID del documento desde la URL
  const [currentSlide, setCurrentSlide] = useState(0);
  const [formData, setFormData] = useState({
    sunCategory: [],
    phCategory: [],
    phLevel: '',
    additionalComments: '',
  });
  const [photo, setPhoto] = useState(null); // Almacena la foto seleccionada
  const [message, setMessage] = useState('');
  const [isEdit, setIsEdit] = useState(false); // Agregar estado para isEdit
  const [documentId, setDocumentId] = useState(null); // Agregar estado para documentId

  // Función para detectar si estamos en modo edición y cargar los datos correspondientes
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const isEdit = queryParams.get('edit') === 'true';
    const documentId = queryParams.get('id');

    if (isEdit && documentId) {
      const fetchData = async () => {
        try {
          const response = await axios.get(`https://soil.quanta-labs.com/form/pregunta_1/${documentId}`);
          setFormData(response.data.document || response.data);
        } catch (error) {
          console.error('Error al cargar los datos:', error);
          setMessage('Error al cargar los datos');
        }
      };

      fetchData();
    }
  }, [id]);

  // Maneja el cambio de valor en los campos del formulario
  const handleChange = (field) => (e) => {
    setFormData({
      ...formData,
      [field]: e.target.value,
    });
  };

  // Maneja el cambio de la foto seleccionada
  const handlePhotoChange = (e) => {
    const selectedFile = e.target.files[0];
    setPhoto(selectedFile);
  };

  // Función para gestionar el envío de los datos mediante PATCH
  const handleSubmit = async () => {
    try {
      const queryParams = new URLSearchParams(window.location.search);
      const isEdit = queryParams.get('edit') === 'true';
      const documentId = queryParams.get('id');

      const jsonData = {
        document: {
          sunCategory: formData.sunCategory,
          phCategory: formData.phCategory,
          phLevel: formData.phLevel,
          additionalComments: formData.additionalComments,
        }
      };

      let response;
      if (isEdit && documentId) {
        response = await axios.patch(
          `https://soil.quanta-labs.com/form/pregunta_1/${documentId}`,
          jsonData,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer test',
            }
          }
        );
      } else {
        const formDataToSend = new FormData();
        formDataToSend.append('document', JSON.stringify(jsonData.document));

        if (photo) {
          formDataToSend.append('file', photo, photo.name);
        }

        response = await axios.post(
          'https://soil.quanta-labs.com/form/pregunta_1/',
          formDataToSend,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: 'Bearer test',
            }
          }
        );
      }

      console.log('Respuesta de la API:', response.data);
      setMessage('Datos enviados correctamente');
      localStorage.setItem('updateFormList', Date.now());

      // Espera un poco antes de navegar para asegurar que los datos se han actualizado
      setTimeout(() => {
        navigate('/inici');
      }, 1000);
    } catch (error) {
      console.error('Error al enviar datos:', error);
      if (error.response) {
        console.error('Respuesta del servidor:', error.response.data);
        setMessage(`Error al enviar datos: ${JSON.stringify(error.response.data)}`);
      } else {
        console.error('Error:', error.message);
        setMessage('Error al enviar datos');
      }
    }
  };


  const handleNext = () => {
    if (currentSlide < 2) {
      setCurrentSlide((prev) => prev + 1);
    } else {
      handleSubmit(); // Enviar datos cuando lleguemos al último slide
    }
  };

  const handlePrevious = () => {
    if (currentSlide > 0) {
      setCurrentSlide((prev) => prev - 1);
    }
  };

  // Función que renderiza las preguntas de cada slide
  const renderSlideContent = () => {
    switch (currentSlide) {
      case 0:
        return (
          <>
            <Typography variant="h4" component="h2" align="center" gutterBottom>
              <br />
              <br />
              CLASSIFICACIÓ DEL SÒL!!
              <br />
              <br />
            </Typography>
            <Typography variant="body1" gutterBottom>
              Segons el tipus i nivell de pH del sòl que heu observat, classifiqueu-lo a la categoria adequada.
              <br />
              INSTRUCCIONS:
              <ul>
                <li>Reviseu les opcions disponibles per a categoritzar el sòl.</li>
                <li>Seleccioneu la categoria que millor descrigui el sòl.</li>
              </ul>
              PREGUNTA 1.1: Quin tipus de sòl hi observeu?
              <br />
            </Typography>
            <Select
              label="Categoria de Sòl"
              variant="outlined"
              fullWidth
              multiple
              value={formData.sunCategory}
              onChange={handleChange('sunCategory')}
              renderValue={(selected) => selected.join(', ')}
              sx={{ marginBottom: '1rem' }}
            >
              <MenuItem value="Sòl sorrenc">
                <Checkbox checked={formData.sunCategory.indexOf('Sòl sorrenc') > -1} />
                <ListItemText primary="Sòl sorrenc" />
              </MenuItem>
              <MenuItem value="Sòl argilós">
                <Checkbox checked={formData.sunCategory.indexOf('Sòl argilós') > -1} />
                <ListItemText primary="Sòl argilós" />
              </MenuItem>
              <MenuItem value="Sòl limós">
                <Checkbox checked={formData.sunCategory.indexOf('Sòl limós') > -1} />
                <ListItemText primary="Sòl limós" />
              </MenuItem>
              <MenuItem value="Sòl humífer o ric en matèria orgànica">
                <Checkbox checked={formData.sunCategory.indexOf('Sòl humífer o ric en matèria orgànica') > -1} />
                <ListItemText primary="Sòl humífer o ric en matèria orgànica" />
              </MenuItem>
              <MenuItem value="Sòl pedregós o rocós">
                <Checkbox checked={formData.sunCategory.indexOf('Sòl pedregós o rocós') > -1} />
                <ListItemText primary="Sòl pedregós o rocós" />
              </MenuItem>
              <MenuItem value="Sòl calcari">
                <Checkbox checked={formData.sunCategory.indexOf('Sòl calcari') > -1} />
                <ListItemText primary="Sòl calcari" />
              </MenuItem>
              <MenuItem value="Sòl mixt (franc)">
                <Checkbox checked={formData.sunCategory.indexOf('Sòl mixt (franc)') > -1} />
                <ListItemText primary="Sòl mixt (franc)" />
              </MenuItem>
            </Select>
          </>
        );

      case 1:
        return (
          <>
            <Typography variant="h4" component="h2" align="center" gutterBottom>
              <br />
              <br />
              pH DEL SÒL!!
              <br />
              <br />
            </Typography>
            <Typography variant="body1" gutterBottom>
              A continuació anem a observar i classificar el nivell de pH del sòl que us trobeu.
              <br />
              INSTRUCCIONS:
              <ul>
                <li>Situat a la teva parcel·la.</li>
                <li>Fes un petit forat al sòl d’uns 5 o 8 centímetres aproximadament.</li>
                <li>Agafa una tira per a mesurar el pH i cobreix la meitat amb terra d’aquest forat.</li>
                <li>Espereu-vos fins que la tira de pH canviï de color.</li>
                <li>Quan s’hagi canviat de color, mira a la caixa de les tires a quin grau de pH correspon i contesta les preguntes següents.</li>
              </ul>
              PREGUNTA 1.2: Quin nivell de pH té el sòl?
              <br />
            </Typography>
            <TextField
              label="Nivell de pH"
              variant="outlined"
              fullWidth
              value={formData.phLevel}
              onChange={handleChange('phLevel')}
              type="number"
            />

            <Typography variant="body1" gutterBottom sx={{ marginTop: '1rem' }}>
              <br />
              PREGUNTA 1.2: A quina categoria el podeu ubicar?
              <br />
            </Typography>
            <Select
              label="Categoria de pH del Sòl"
              variant="outlined"
              fullWidth
              multiple
              value={formData.phCategory}
              onChange={handleChange('phCategory')}
              renderValue={(selected) => selected.join(', ')}
              sx={{ marginBottom: '1rem' }}
            >
              <MenuItem value="Sòl àcid: pH < 7 (vermell o taronja en les proves de pH)">
                <Checkbox checked={formData.phCategory.indexOf('Sòl àcid: pH < 7 (vermell o taronja en les proves de pH)') > -1} />
                <ListItemText primary="Sòl àcid: pH menor que 7 (vermell o taronja en les proves de pH)" />
              </MenuItem>
              <MenuItem value="Sòl neutre: pH = 7 (groc o verd clar)">
                <Checkbox checked={formData.phCategory.indexOf('Sòl neutre: pH = 7 (groc o verd clar)') > -1} />
                <ListItemText primary="Sòl neutre: pH = 7 (groc o verd clar)" />
              </MenuItem>
              <MenuItem value="Sòl bàsic (alcalí): pH > 7 (verd fosc, blau o morat)">
                <Checkbox checked={formData.phCategory.indexOf('Sòl bàsic (alcalí): pH > 7 (verd fosc, blau o morat)') > -1} />
                <ListItemText primary="Sòl bàsic (alcalí): pH més gran que 7 (verd fosc, blau o morat)" />
              </MenuItem>
            </Select>
          </>
        );

      case 2:
        return (
          <>
            <Typography variant="h4" component="h2" align="center" gutterBottom>
              <br />
              <br />
              COMENTARIS ADDICIONALS
              <br />
              <br />
            </Typography>
            <Typography variant="body1" gutterBottom>
              <br />
              Si tens algun comentari o observació addicional sobre el sòl, introdueix-lo a continuació.
              <br />
            </Typography>
            <TextField
              label="Comentaris addicionals"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              value={formData.additionalComments}
              onChange={handleChange('additionalComments')}
              sx={{ marginBottom: '1rem' }}
            />
            <Box sx={{ marginTop: '2rem', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem' }}>
              <Button variant="contained" component="label">
                <br />
                Afegeix Foto
                <input type="file" hidden onChange={handlePhotoChange} />
              </Button>
              {photo && (
                <Box sx={{ marginTop: '10px' }}>
                  <img
                    src={URL.createObjectURL(photo)}
                    alt="Preview"
                    style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '5px' }}
                  />
                </Box>
              )}
            </Box>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <Box className="box-content">
      <Box className="banner-container">
        <img src={BannerImage} alt="Banner" className="banner-image" />
        <br />
        <br />
      </Box>
      {renderSlideContent()}
      <Box sx={{ marginTop: '2rem', display: 'flex', justifyContent: 'space-between' }}>
        {currentSlide > 0 && (
          <Button variant="outlined" onClick={handlePrevious}>
            Anterior
          </Button>
        )}
        <Button variant="outlined" component={Link} to="/inici">
          Tornar a l'inici
        </Button>
        <Button variant="contained" onClick={handleNext}>
          {currentSlide < 2 ? 'Següent' : 'Enviar Dades'}
        </Button>
      </Box>
      {message && (
        <Typography variant="body2" color="error" align="center" sx={{ marginTop: '1rem' }}>
          {message}
        </Typography>
      )}
    </Box>
  );
};

export default Bloc1Page;
