import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Select, MenuItem, Checkbox, ListItemText } from '@mui/material';
import { useNavigate, useParams, Link } from 'react-router-dom';
import BannerImage from '../assets/banner-image.png';
import '../App.css';
import axios from 'axios';

const Bloc3Page = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [currentSlide, setCurrentSlide] = useState(0);
  const [formData, setFormData] = useState({
    rockBool: '',
    rockCategory: [],
    mineralBool: '',
    mineralCategory: [],
  });
  const [photo, setPhoto] = useState(null);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const isEdit = queryParams.get('edit') === 'true';
    const documentId = queryParams.get('id');

    if (isEdit && documentId) {
      const fetchData = async () => {
        try {
          const response = await axios.get(`https://soil.quanta-labs.com/form/pregunta_3/${documentId}`);
          setFormData(response.data.document || response.data);
        } catch (error) {
          console.error('Error al cargar los datos:', error);
          setMessage('Error al cargar los datos');
        }
      };

      fetchData();
    }
  }, [id]);

  const handleNext = () => {
    if (currentSlide < 1) {
      setCurrentSlide((prev) => prev + 1);
    } else {
      handleSubmit();
    }
  };

  const handlePrevious = () => {
    if (currentSlide > 0) {
      setCurrentSlide((prev) => prev - 1);
    }
  };

  const handleChange = (field) => (e) => {
    setFormData({
      ...formData,
      [field]: e.target.value,
    });
  };

  const handlePhotoChange = (e) => {
    const selectedFile = e.target.files[0];
    setPhoto(selectedFile);
  };


  const handleSubmit = async () => {
    try {
      const queryParams = new URLSearchParams(window.location.search);
      const isEdit = queryParams.get('edit') === 'true';
      const documentId = queryParams.get('id');

      const jsonData = {
        document: {
          rockBool: formData.rockBool,
          rockCategory: formData.rockCategory,
          mineralBool: formData.mineralBool,
          mineralCategory: formData.mineralCategory,
        }
      };

      let response;
      if (isEdit && documentId) {
        response = await axios.patch(
          `https://soil.quanta-labs.com/form/pregunta_3/${documentId}`,
          jsonData,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer test',
            }
          }
        );
      } else {
        const formDataToSend = new FormData();
        formDataToSend.append('document', JSON.stringify(jsonData.document));

        if (photo) {
          formDataToSend.append('file', photo, photo.name);
        }

        response = await axios.post(
          'https://soil.quanta-labs.com/form/pregunta_3/',
          formDataToSend,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: 'Bearer test',
            }
          }
        );
      }

      console.log('Respuesta de la API:', response.data);
      setMessage('Datos enviados correctamente');
      localStorage.setItem('updateFormList', Date.now());

      // Espera un poco antes de navegar para asegurar que los datos se han actualizado
      setTimeout(() => {
        navigate('/inici');
      }, 1000);
    } catch (error) {
      console.error('Error al enviar datos:', error);
      if (error.response) {
        console.error('Respuesta del servidor:', error.response.data);
        setMessage(`Error al enviar datos: ${JSON.stringify(error.response.data)}`);
      } else {
        console.error('Error:', error.message);
        setMessage('Error al enviar datos');
      }
    }
  };






  const renderSlideContent = () => {
    switch (currentSlide) {
      case 0:
        return (
          <>
            <Typography variant="h4" align="center" gutterBottom>
              <br />
              <br />
              PEDRES I ROQUES!!
              <br />
              <br />
            </Typography>
            <Typography variant="body1" gutterBottom>
              Iniciem el tercer apartat, dedicat a pedres, roques i minerals. Concretament començarem per les pedres i roques.
              <br />
              PREGUNTA 3.1: Hi podeu observar pedres o roques?
              <br />
            </Typography>
            <Select
              label="Resposta"
              variant="outlined"
              fullWidth
              value={formData.rockBool}
              onChange={handleChange('rockBool')}
              sx={{ marginBottom: '1rem' }}
            >
              <MenuItem value="si">Sí</MenuItem>
              <MenuItem value="no">No</MenuItem>
            </Select>
            <Typography variant="body1" gutterBottom>
              <br />
              PREGUNTA 3.1: A quina categoria les podeu ubicar?
              <br />
            </Typography>
            <Select
              label="Categoria de Roques"
              variant="outlined"
              fullWidth
              multiple
              value={formData.rockCategory}
              onChange={handleChange('rockCategory')}
              renderValue={(selected) => selected.join(', ')}
              sx={{ marginBottom: '1rem' }}
            >
              <MenuItem value="Roques ígnies: Granit i Basalt">
                <Checkbox checked={formData.rockCategory.indexOf('Roques ígnies: Granit i Basalt') > -1} />
                <ListItemText primary="Roques ígnies: Granit i Basalt" />
              </MenuItem>
              <MenuItem value="Roques sedimentàries: Arenisca, Calcària, Conglomerat">
                <Checkbox checked={formData.rockCategory.indexOf('Roques sedimentàries: Arenisca, Calcària, Conglomerat') > -1} />
                <ListItemText primary="Roques sedimentàries: Arenisca, Calcària, Conglomerat" />
              </MenuItem>
              <MenuItem value="Roques metamòrfiques: Marbre, Gneis, Pissarra">
                <Checkbox checked={formData.rockCategory.indexOf('Roques metamòrfiques: Marbre, Gneis, Pissarra') > -1} />
                <ListItemText primary="Roques metamòrfiques: Marbre, Gneis, Pissarra" />
              </MenuItem>
            </Select>
          </>
        );
      case 1:
        return (
          <>
            <Typography variant="h4" align="center" gutterBottom>
              <br />
              <br />
              MINERALS!!
              <br />
              <br />
            </Typography>
            <Typography variant="body1" gutterBottom>
              Seguim amb l’observació de minerals i la seva classificació.
              <br />
              PREGUNTA 3.2: Hi podeu observar minerals?
            </Typography>
            <Select
              label="Resposta"
              variant="outlined"
              fullWidth
              value={formData.mineralBool}
              onChange={handleChange('mineralBool')}
              sx={{ marginBottom: '1rem' }}
            >
              <MenuItem value="si">Sí</MenuItem>
              <MenuItem value="no">No</MenuItem>
            </Select>
            <Typography variant="body1" gutterBottom>
              PREGUNTA 3.2: A quina categoria els podeu ubicar?
            </Typography>
            <Select
              label="Categoria de Minerals"
              variant="outlined"
              fullWidth
              multiple
              value={formData.mineralCategory}
              onChange={handleChange('mineralCategory')}
              renderValue={(selected) => selected.join(', ')}
              sx={{ marginBottom: '1rem' }}
            >
              <MenuItem value="Quars">
                <Checkbox checked={formData.mineralCategory.indexOf('Quars') > -1} />
                <ListItemText primary="Quars" />
              </MenuItem>
              <MenuItem value="Mica">
                <Checkbox checked={formData.mineralCategory.indexOf('Mica') > -1} />
                <ListItemText primary="Mica" />
              </MenuItem>
              <MenuItem value="Feldspat">
                <Checkbox checked={formData.mineralCategory.indexOf('Feldspat') > -1} />
                <ListItemText primary="Feldspat" />
              </MenuItem>
              <MenuItem value="Calcita">
                <Checkbox checked={formData.mineralCategory.indexOf('Calcita') > -1} />
                <ListItemText primary="Calcita" />
              </MenuItem>
              <MenuItem value="Pirita">
                <Checkbox checked={formData.mineralCategory.indexOf('Pirita') > -1} />
                <ListItemText primary="Pirita" />
              </MenuItem>
            </Select>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Box className="box-content">
      <Box className="banner-container">
        <img src={BannerImage} alt="Banner" className="banner-image" />
        <br />
        <br />
      </Box>
      {renderSlideContent()}
      <Box sx={{ marginTop: '2rem', display: 'flex', justifyContent: 'space-between' }}>
        {currentSlide > 0 && (
          <Button variant="outlined" onClick={handlePrevious}>
            Anterior
          </Button>
        )}
        <Button variant="outlined" component={Link} to="/inici">
          Tornar a l'inici
        </Button>
        <Button variant="contained" onClick={handleNext}>
          {currentSlide < 1 ? 'Següent' : 'Enviar Dades'}
        </Button>
      </Box>
      {message && (
        <Typography variant="body2" color="error" align="center" sx={{ marginTop: '1rem' }}>
          {message}
        </Typography>
      )}
    </Box>
  );
};

export default Bloc3Page;
