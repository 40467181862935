import React, { useState, useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

// Asume que estas importaciones existen en tu proyecto
import ImatgeNen from '../assets/boy-character.png';
import ImatgeNena from '../assets/girl-character.png';

const CharacterSelectionPage = () => {
  const [selectedCharacter, setSelectedCharacter] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const savedCharacter = localStorage.getItem('selectedCharacter');
    if (savedCharacter) {
      // Si ya hay un personaje seleccionado, redirige al usuario a la página de inicio
      navigate('/inici');
    }
  }, [navigate]);

  const handleCharacterSelect = (character) => {
    setSelectedCharacter(character);
    localStorage.setItem('selectedCharacter', character);

    // Recargar la página automáticamente después de la selección del personaje
    setTimeout(() => {
      window.location.reload();
    }, 100); // Un pequeño retraso para asegurarse de que el personaje se guarde antes de recargar
  };

  return (
    <Box sx={{ textAlign: 'center', padding: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Escull el teu personatge
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', gap: 4 }}>
        <Button
          onClick={() => handleCharacterSelect('')}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 2,
            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' }
          }}
        >
          <img src={ImatgeNen} alt="Personaje Seleccionat" style={{ width: 150, height: 150, marginBottom: 1 }} />
          <Typography>Nen</Typography>
        </Button>
        <Button
          onClick={() => handleCharacterSelect('')}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 2,
            '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' }
          }}
        >
          <img src={ImatgeNena} alt="Personaje Seleccionat" style={{ width: 150, height: 150, marginBottom: 1 }} />
          <Typography>Nena</Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default CharacterSelectionPage;
